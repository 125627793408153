import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActivitiesService } from '../../../services/activities.service';

@Component({
  selector: 'app-activities-logs',
  templateUrl: './logs.component.html',
  styleUrl: './logs.component.css'
})
export class ActivitiesLogsComponent implements OnInit {
  @ViewChild("modalDetail")
  modalDetail:any;
  modalDetailRef:any;
  
  
  
  
  logs:any[]=[];

  @Input()
  id_activity=0;

  log:any={};


  constructor(
    private modalService:NgbModal,
    private activitiesService:ActivitiesService
    
  
  ){

  }
  ngOnInit(): void {
    this.getLogs();
  }

  ngOnChanges(){
    
  }

  getLogs(){
    //if(this.id_activity>0){
      this.activitiesService.getLogs(this.id_activity).subscribe((result)=>{
        this.logs=result;
      });
    //}
  }

  openDetail(l:any=null){
    if(l==null)
      this.log={"id":0,"subject":"","description":"","id_activity":this.id_activity};
    else
      this.log=l;
    this.modalDetailRef=this.modalService.open(this.modalDetail);
  }


  closeDetail(){
    this.modalDetailRef.close("success");
  }


  save(){
    this.activitiesService.addLog(this.log,()=>{
      this.getLogs();
      this.closeDetail();
    });
  }

}
