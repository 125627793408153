<div class="row" *ngFor="let record of list" style="max-height:60vh; overflow:auto">
    <div class="col-md-2">
        <small *ngIf="!record.date">Oggi</small>
        <small>{{record.date | date:'EEEE dd/MM/yyyy'}}</small>
    </div>
    <div class="col-md-9">
        <textarea id="inputDescription" class="form-control form-control-sm" rows="1" onblur="this.rows=1;" onfocus="this.rows=4;" [(ngModel)]="record.value" placeholder="Scrivi il testo..."></textarea>
    </div>
    <div class="col-md-1 float-right">
        <a class="btn btn-sm btn-danger float-right"  title="Elimina nota" (click)="deleteRecord(record)"><i class="far fa-trash"></i></a>
    </div>
</div>
<a (click)="newRecord()" class="btn btn-sm btn-success"  title="Aggiungi nota"><i class="fa fa-plus"></i> Aggiungi</a>