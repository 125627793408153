<app-windowlist  [model]="this">
    <div class="row py-1">
        <div class="col-lg-2 pr-md-0" *ngIf="filterbox">
            <app-filtercontainer [title]="'Filtra per date e sedi'">
                <app-filterdate [date_type]="100" [class]="'card p-1'" [filter_date]="filter_date" (onSelect)="getItems()"></app-filterdate>
                <ul class="card  small-box list-group p-1 mt-2">
                    <li class="list-group-item" [class.active]="filter_id_shop_end.value==''" (click)="filter_id_shop_end.value='';getItems()">Tutte le sedi</li>
                    <li class="list-group-item text-truncate" *ngFor="let s of shops" [class.active]="filter_id_shop_end.value==s.id" (click)="filter_id_shop_end.value=s.id;getItems()">{{s.name}}</li>
                    <div *ngIf="shops.length>2" class="icon"><i class="fas fa-store-alt "></i></div>
                </ul>
            </app-filtercontainer>
        </div>
        <div [class.col-lg-10]="filterbox" [class.col-lg-12]="!filterbox" >
            <div class="card mb-0">
                <div class="p-0">
                    <div class="row" *ngIf="filterbox">
                        <div class="col-lg-6">
                            <div class="input-group input-group-info input-group-sm p-1">
                                <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="nome cliente o descrizione..." [(ngModel)]="filter_search.value" (keyup.enter)="updateList()">
                                <input type="text" class="form-control form-control-sm" placeholder="numero documento" style="max-width:150px;" [(ngModel)]="filter_reference.value" (keyup.enter)="updateList()">
                                <div class="input-group-append ">
                                    <button type="submit" class="btn btn-default text-primary"  title="avvia ricerca" (click)="updateList()"><i class="fas fa-search"></i></button>
                                    <button class="btn btn-default text-danger" *ngIf="filter_search.value!='' || filter_reference.value!=''"  title="annulla ricerca" (click)="filter_search.value='';filter_reference.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                                </div>
                            </div>
                        </div>
                        <div class="col-6 col-lg-3">
                            <select class="form-control form-control-sm m-1" [(ngModel)]="this.filter_state.value" (change)="getItems()"
                                [class.alert-warning]="this.filter_state.value==1"
                                [class.alert-success]="this.filter_state.value==2"
                                [class.alert-danger]="this.filter_state.value==3"
                                [class.alert-info]="this.filter_state.value==4"
                                [class.alert-info]="this.filter_state.value==5"
                                [class.alert-warning]="this.filter_state.value==6">
                                <option value="0">Tutti gli stati</option>
                                <option value="1">da Confermare</option>
                                <option value="2">Confermato</option>
                                <option value="3">Annullato</option>
                                <option value="4">Ordinato</option>
                                <option value="5">Spedito</option>
                                <option value="6">Parziale</option>
                            </select>
                        </div>
                        <div class="col-6 col-lg-3">
                            <div class="pr-1">
                                <app-windowlistbuttons 
                                [model]="this" 
                                [table]="'documents'" 
                                [openDetailExtra]="[{'name':'id_type','value':2},{'name':'type_document','value':10},{'name':'state','value':1},{'name':'description','value':'Trasferimento'}]" 
                                [funs]="[]"
                                ></app-windowlistbuttons>
                            </div>
                            <!-- <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="far fa-trash-alt"></i></button>
                            <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right ml-1 "><i class="fas fa-trash"></i></button>
                            <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-undo"></i></button>
                            <button  (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 "><i class="fas fa-file-export"></i></button>
                            <button (click)="openDetail(null,[{'name':'id_type','value':2},{'name':'type_document','value':10},{'name':'state','value':1},{'name':'description','value':'Trasferimento'}])"  class="btn btn-success btn-xs float-right ml-1"><i class="fas fa-plus"></i></button> -->
                        </div>
                    </div>

                    <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                        <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>
    
                    </div>
                    <form *ngIf="list && list.length>0" [formGroup]="form">
                        <table class="table table-nowrap table-striped table-sm table-head-fixed table-hover m-0 text-sm">
                            <thead class="bg-light">
                                <th>
                                    <input #checkboxselectall type="checkbox"
                                    (change)="onChangeAll($event.target.checked)" />
                                </th>
                                <th>Data</th>
                                <th>Documento</th>
                                <th>Causale</th>
                                <th class="text-right">Da</th>
                                <th></th>
                                <th>A</th>
                                <th class="text-center">Stato</th>
                                <th></th>
                            </thead>
                            <tbody>
                                <!-- elemento ripetuto -->
                                <tr *ngFor="let record of list">
                                    <td>
                                        <input type="checkbox" #checkrecord id_record="{{record.id}}"
                                        (change)="onChange(record.id, $event.target.checked)" [checked]="checkedAll"
                                        />
                                        <div   title="Trasferimento" class="badge badge-secondary ml-2">TR</div>
                                    </td>
                                    <td>{{record.date | date:"dd/MM/yyyy"}}</td>
                                    <td>{{record.reference}} {{record.referenceprefix}}</td>
                                    <td>{{record.description}}
                                        <ng-container *ngIf="record.note">
                                            <hr/>
                                            <small><i class="fa fa-exclamation"></i>&nbsp;{{record.note}}</small>
                                        </ng-container>
                                    </td>
                                    <td class="text-right">{{record.shop_start.name}}</td>
                                    <td class="text-center"><i class="fas fa-long-arrow-alt-right"></i></td>
                                    <td class="text-left">{{record.shop_end.name}}</td>
                                    <td class="text-center">
                                        <app-statedocument [state]="record.state"></app-statedocument>
                                    </td>
                                    <td>
                                         <button type="button" *ngIf="mode!='modal'"  (click)="openDetail(record)" routerLinkActive="active"  title="Modifica / Vedi" class="btn btn-secondary btn-xs float-right ml-1 mt-1">
                                                <i class="fas fa-pencil-alt"></i></button>
                                        <button type="button" *ngIf="mode!='modal'"  (click)="exportInRow(record)"   title="Stampa" class="btn btn-warning btn-xs float-right ml-1 mt-1">
                                            <i class="fas fa-print"></i></button>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>