import { Component, Input, OnInit } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { ToastMode } from 'projects/core/src/include/structures';
import { Documents } from '../../../db/documents';
import { DocumentsService } from '../../../services/documents.service';
import { DocumentsDetailsComponent } from '../documents-details/documents-details.component';

@Component({
  selector: 'app-sendinvoice',
  templateUrl: './sendinvoice.component.html',
  styleUrls: ['./sendinvoice.component.css']
})
export class SendinvoiceComponent implements OnInit {

  @Input()
  documents:Documents[]=[];

  VerifyDone = false;

  get documentToSend(){

    let result=0;
    for(let i=0;i<this.documents.length;i++){
      let d=this.documents[i];
      // solo fatture e notula
      if(d.typedocument==1 || d.typedocument==2 || d.typedocument==12 || d.typedocument==3) {
        // solo stati da inviare, errore e non definito 
        if(d.sdi_status==0 || d.sdi_status==3 || d.sdi_status==-1) {      
          result=result+1;
        }
      }
    }

    return result;
  }

  total_errors=0;
  total_success=0;
  afterSend=false;

  constructor(
    private documentsService:DocumentsService
  ) { }

  ngOnInit(): void {
    
    if(this.documentToSend>0)
      this.checkDocuments()

  }


  close(reason="success"){
    this['modalWindow'].close(reason);
  }


  checkDocuments(){
    //raccogli tutti gli id
    let ids=[];
    for(let d of this.documents){
      ids.push(d.id);
    }

    this.documentsService.checkXMLEInvoice(ids).subscribe((result)=>{
      this.documents=result;
      Globals.message.showToaster("Verifica integrità completata",ToastMode.SUCCESS);
      this.VerifyDone = true;
    });
  }


  openDetail(record){
    Globals.modal.showModal(DocumentsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":record.id}
    ],(instance)=>{
      if(instance!=null){
        record=instance['record'];
        for(let i=0;i<this.documents.length;i++){
          
          if(this.documents[i]['id']==record.id){
            this.documents[i]=record;
          }
        }

        
      }
    });
  }


  downloadXML(record){
    var file = new Blob([record['filexml']], {type: 'application/xml'});
       var fileURL = URL.createObjectURL(file);
       window.open(fileURL);

   
  }


  sendToSdi(){
    this.afterSend=false;
    let ids=[];
    for(let d of this.documents){
      if((d.sdi_status==0 || d.sdi_status==-1 || d.sdi_status==3) && (d.sdi_message=="" || d.sdi_message==undefined || d.sdi_message==null))
      
        ids.push(d.id);
    }
  
    Globals.modal.showConfirm("","Confermi di voler inviare i documenti selezionati allo SdI?",()=>{
      
      Globals.setLoading(true);
      this.total_errors=0;
      this.total_success=0;
      this.documentsService.sendMultipleEInvoice(ids).subscribe((result)=>{
        Globals.setLoading(false);
        this.documents=result;
        for(let d of this.documents){
          if(d.sdi_status==3)
            this.total_errors=this.total_errors+1;
          else
            this.total_success=this.total_success+1;
        }
        this.afterSend=true;
        // this.close();

      })
    },"Sì. Invia","No. Annulla");
  }

}