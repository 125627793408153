<section>    
    <div [class.modal-content]="model.mode=='modal' && showCard">
        <div *ngIf="model.mode=='modal' && showCard" class="modal-header bg-light">
            <b>{{model.titleModal}}</b>
        </div>
        <div [class.modal-body]="model.mode=='modal' && showCard">
            <ng-content #content></ng-content>
        </div>
        <div class="modal-footer py-1" *ngIf="model.mode=='modal' && showCard">
            <button class="btn btn-secondary" (click)="close()"><i class="fas fa-times mr-2"></i>Chiudi<small class="KeyFunction ml-2">(ESC)</small></button>
        </div>
    </div>    
</section>


