import { Component, OnInit,ViewChild,Input, TemplateRef, AfterViewInit} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Model } from 'projects/core/src/include/model';
import { UserService } from 'projects/core/src/lib/user.service';
import { Helper } from 'projects/core/src/helper';
import { Globals } from 'projects/core/src/globals';
import { CashFlowDetailsComponent } from '../../cash-flow/cash-flow-details/cash-flow-details.component';
import { Activities } from '../../../db/activities';
import { Type } from '../../../db/type';
import { User } from 'projects/core/src/include/structures';
import { ActivitiesService } from '../../../services/activities.service';
import { TypesService } from '../../../services/types.service';
import { ReferenceComponent } from '../../../modules/reference/reference.component';
import { AddressesService } from '../../../services/addresses.service';
import { CashFlowComponent } from '../../cash-flow/cash-flow-list/cash-flow-list.component';
import { CashflowService } from '../../../services/cashflow.service';
import { FormDetailComponent } from '../../forms/form-list/form-detail/form-detail.component';
import { SearchgpsComponent } from '../../../modules/searchgps/searchgps.component';
import { AddressComponent } from '../../address/address.component';
import { AddressDetailsComponent } from '../../address/address-details/address-details.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from '../../../services/notification.service';


@Component({
  selector: 'app-activities-details',
  templateUrl: './activities-details.component.html',
  styleUrls: ['./activities-details.component.sass']
})
export class ActivitiesDetailsComponent extends Model<Activities> implements OnInit,AfterViewInit {
  @ViewChild("cashflow")
  cashflow:CashFlowComponent;

  @ViewChild("modalSelectType")
  modalSelectType:TemplateRef<any>;
  modalSelectTypeRef;

  @Input()
  id_address:number;

  @Input()
  new_date:Date;

  @Input()
  reference:any;

  @Input()
  table:string;

  @ViewChild("referenceRecord")
  referenceRecord:ReferenceComponent;


  tab=1;

  isGetReference:boolean=false; //indica che la finestra è stata aperta con un record di riferimento da agganciare

  type_activities:Type[];
  type_activity_selected:Type;
  users:User[];
  
  field_required=['date','reference','id_type'];
  modulename="activities";

  isNew=false;

  get missingValuesCount (){
    if(!Array.isArray(this.record.missingValues))
      return 0;
    return this.record.missingValues.length;
  }
  
  get missingValuesUndefinedCount(){
    if(!Array.isArray(this.record.missingValues))
      return;
    let count=0;
    for(let f of this.record.missingValues)
        if(f.value=="" || f.value==undefined)
          count++;
    return count;
  }


  constructor(
    private route1: ActivatedRoute,
    private activitiesService: ActivitiesService,
    public addressesService:AddressesService,
    private typesService: TypesService,
    private userService:UserService,
    private cashflowService:CashflowService,
    private modalService:NgbModal,
    private notificationService:NotificationService,

    
    ) {
      super(route1,activitiesService); 
    
    
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.title = 'Attività Dettaglio';
    if(this.mode!="modal")
      this.route1.params.subscribe((params) => {
        this.id = params.id
        if(params.mode!=undefined)
          this.isGetReference=true;
      
      });

    this.afterSave=()=>{
      let id_notification=this.module.getParam("enable_notify_after_created",0);
      if(id_notification>0 && this.isNew){
        
        this.notificationService.showPreviewNotification(this.record.addressItem.contacts,this.record,"activities",()=>{

        },this.record.id_address,id_notification);
      }
    }
     


  }

  ngAfterViewInit(): void {
    this.afterGetItem=()=>{
      if(this.id==0){
        this.isNew=true;
         //this.record.id_type=this.type_activities[0].id;
         this.record.date=Helper.convertDateControl(null);
         this.record.phase=1;
         this.record.priority=1;
         this.record.id_user=Globals.user.id;
         if(this.reference){
           this.setReference(this.reference,this.table);
         }

         if( this.id_address>0){
           this.addressesService.getItem(this.id_address).subscribe((item)=>{
             this.record.reference=item;
             this.record.id_table=item.id;
             this.record.table="addresses";
             this.record.id_address=item.id;
             this.record.addressItem=item;
             this.record.id_address=this.id_address;
           
           });
         }

         this.openModalSelectType();
       



      }else{
       try{
         this.record.user_action=JSON.parse(this.record.user_action);
       }catch{
         this.record.user_action={};
       }

      }
      
      //associa la tipologia giusta
      for(let t of this.type_activities){
        if(t.id==this.record.id_type){
          this.record.type=t;
          break;
        }
      }

      if(this.new_date!=undefined){
        this.record.date=this.new_date;
        this.record.phase=1;
        this.record.reschedule=null;
      }
      this.calculateGross();

      
    }

    this.typesService.getTypes("activities").subscribe((items)=>{
      this.type_activities=items;
      
      this.userService.getUsers().subscribe((items)=>{
        this.users=items;
        this.getItem();
      });
    });
  
   
    
  


 

 this.afterSelectionRecord=((r,table)=>{
  this.setReference(r,table);
 });
  }

  setReference(r,table):void{
    this.record.reference=r;
    this.record.table=table;
    this.record.id_table=r['id'];

    this.record.id_address=ReferenceComponent.getIdAddress(table,this.record.reference);
    
    this.addressesService.getItem(this.record.id_address).subscribe((item)=>{
      this.record.addressItem=item;
    })

    if(this.record.reference.table=='shops'){
      this.setActivityLocation(this.record.reference);
    }

    if(this.record.reference.table=='installations'){
      this.record.description=this.record.reference.sn;
    }

    if(this.record.reference.table=='addresses'){
      this.setActivityLocation(this.record.reference);
    }

    if(this.record.reference['shop']){
      this.setActivityLocation(this.record.reference['shop']);
    }

    
   
  }

  setActivityLocation(record){
    this.record.latitude=0;
    this.record.longitude=0;
    this.record.id_shop=record['id'];
    this.record.nation=record['nation'];
    this.record.address=record['address'];
    this.record.city=record['city'];
    this.record.zip=record['zip'];
    this.record.country=record['country'];
    this.record.opening_time=record['opening_time'];
    if(record['latitude']) this.record.latitude=record['latitude'];
    if(record['longitude']) this.record.longitude=record['longitude'];
    
  }
  /*
  connectActivity(type:string){
  
    this.referenceRecord.connectActivity(type,(record,t)=>{
      this.setReference(record,t);
    })
  }*/

  connectActivity(record){
    if(record==null){
      this.record.reference=null;
      this.record.table="";
      this.record.id_table=0;
    }else{
      this.setReference(record,record.table);
    }
    
  }

  
 confirm(){
   this.record.phase=3;
   this.activitiesService.save(this.record,()=>{

   });
 }


 calculateGross(){
  this.record.gross=parseFloat((this.record.amount*(1+this.record.tax/100)).toFixed(2));

}

calculateNet(){
  this.record.amount=parseFloat((this.record.gross/(1+this.record.tax/100)).toFixed(2));

}



openCashFlow(id){
  Globals.modal.showModal(CashFlowDetailsComponent,[{"name":"mode","value":"modal"},{"name":"id","value":id}],()=>{

  });
}
  

addPayment(){
  Globals.modal.showModal(CashFlowDetailsComponent,[
    {"name":"mode","value":"modal"},
    {"name":"id_table","value":this.record.id},
    {"name":"table","value":"activities"},
    {"name":"id_address","value":this.record.id_address},
    {"name":"amount","value":this.record.amount},
    {"name":"tax","value":this.record.tax},
    {"name":"paid","value":this.record.paid},
    {"name":"id_user","value":this.record.id_user},
    {"name":"id_type","value":1},
    {"name":"description","value":this.record.type.name+" "+this.record.description},
    {"name":"method_payment","value":this.record.method_payment},
    {"name":"reference","value":this.record}
  ],(instance)=>{
    if(instance!=null){
      this.record.id_cashflow=instance['record']['id'];
      this.cashflow.updateList();
    }
  });
}

LinkPayment(){
  Globals.modal.showModal(CashFlowComponent,[{"name":"mode","value":"modal"}],(instance)=>{
    if(instance!=null){
      let d=instance['recordSelected'];
      if(confirm("Attribuire il pagamento a questo documento?")){
        d.id_table=this.record.id;
        d.table="activities";
        this.record.id_cashflow=d.id;
        this.cashflowService.save(d,()=>{
          this.cashflow.updateList();
        
        });
      }
      
    }
    
  });
}

openForm(){
  Globals.modal.showModal(FormDetailComponent,[
    {"name":"mode","value":"modal"},
    {"name":"id","value":this.record.id_form},
  ],()=>{

  });
}

openSearchGPS(){
  Globals.modal.showModal(SearchgpsComponent,[{"name":"search","value":this.record.address+","+this.record.city},{"name":"latitude","value":this.record.latitude},{"name":"longitude","value":this.record.longitude}],(instance)=>{
    if(instance!=null){
      this.record.latitude=instance['latitude'];
      this.record.longitude=instance['longitude'];
      this.record.address=instance['address'];
      this.record.city=instance['city'];
      this.record.zip=instance['zip'];
    }
  });
}

verifyCoordinate() {

  if (this.record.latitude != null) {
    var arr = this.record.latitude.toString().split(",");
    this.record.latitude = parseFloat(arr[0]);
    if (arr.length > 1) this.record.longitude = parseFloat(arr[1]);
  }
  
 }

 selectAddress(){
  Globals.modal.showModal(AddressComponent,[{"name":"mode","value":"modal"}],(instance)=>{
    if(instance['recordSelected']){
      this.record.addressItem=instance['recordSelected'];
      this.record.id_address=this.record.addressItem.id;
    }
  })
}

addAddress(){
  Globals.modal.showModal(AddressDetailsComponent,[{"name":"mode","value":"modal"}],(instance)=>{
    if(instance!=null){
      this.record.addressItem=instance['record'];
      this.record.id_address=this.record.addressItem.id;
    }
  })
}

OnViewAddress(r){
  Globals.modal.showModal(AddressDetailsComponent,[
    {"name":"mode","value":"modal"},
    {"name":"id","value":r.id}
  ],(instance)=>{
    if(instance!=null)
      this.OnSelectAddress(instance['record']);
  });
}

OnSelectAddress(r){
  if(r){
    this.record.addressItem=r;
    this.record.id_address=r.id;
  }
}

openModalSelectType(){
  this.modalSelectTypeRef=this.modalService.open(this.modalSelectType);
}

closeModalSelectType(){
  this.modalSelectTypeRef.close("success");
}

getDay(){
  const act_date=new Date(this.record.date);
  return act_date.getDay();
}
 /**
 * Verifica se l'attività potrebbe essere chiusa
 */
 checkOpeningTime(){

  if(!this.record.opening_time)
      return true;

  var opening_time=this.record.opening_time;
 
 
  for(let i=0;i<opening_time.length;i++){

    
    if(parseInt(opening_time[i].day)==this.getDay()){
      
      if(opening_time[i].from1=="" && opening_time[i].to1=="" && opening_time[i].from2=="" && opening_time[i].to2==""){
       
        
        return false;
      }
    }
  }

  return true;

}


}
