<app-windowlist [model]="this"> 
    <div class="row">
        <div *ngIf="isModuleEnabled('shops')" class="col-lg-2 pr-md-0" >
            <app-filtercontainer [title]="'Filtra per sedi'">
                <ul class="card small-box list-group p-1 " *ngIf="user.isAdmin() ">
                    <li class="list-group-item text-truncate" *ngFor="let s of shops" [class.active]="filter_created_from.value==s.id" (click)="filter_created_from.value=s.id;getItems()">{{s.name}}</li>
                    <li class="list-group-item"  [class.active]="filter_created_from.value=='0'" (click)="filter_created_from.value='0';getItems()">Nessuna sede</li>
                    <div class="icon"><i class="fa fa-store-alt "></i></div>
                </ul>
            </app-filtercontainer>
        </div>
        <div class="col-lg-10">
            <div class="card card-outline p-2">
                <div class="form-group row mt-2">
                    <div class="col-5 text-right"><label>Giorno</label></div>
                    <div class="col-7 col-lg-2">
                        <input type="date" class="form-control" [(ngModel)]="date" (change)="getItems()">
                    </div>
                </div>
                <div class="row" *ngIf="list">
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header bg-success">
                                <div class="row">
                                    <div class="col-6">
                                        <h5>Entrate</h5>
                                    </div>
                                    <div class="col-6">
                                        <h5 class="text-right">+{{list['total_in'] | currency:"&euro;"}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body bg-light" *ngIf="list['in']">
                                <table class="table border table-nowrap table-sm table-head-fixed table-hover">
                                    <thead class="alert-success">
                                        <th>Metodo di pagamento</th>
                                        <th class="text-right">Importo</th>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center" *ngIf="list['in']['methodpayment'].length==0">
                                            <td colspan="2"><i>nessun metodo di pagamento</i></td>
                                        </tr>
                                        <tr *ngFor="let r of list['in']['methodpayment']">
                                            <td>{{r.method_payment}}</td>
                                            <td class="text-right">{{r.total | currency:"&euro;"}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="">
                                        <tr>
                                            <td><b>TOTALE</b></td>
                                            <td class="text-right"><b>{{list['in']['total_methodpayment'] | currency:"&euro;"}}</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <br/>
                                <table class="table border table-nowrap table-sm table-head-fixed table-hover">
                                    <thead class="alert-success">
                                        <th>Documento</th>
                                        <th class="text-right">Importo</th>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center" *ngIf="list['in']['documents'].length==0">
                                            <td colspan="2"><i>nessun documento</i></td>
                                        </tr>
                                        <tr *ngFor="let r of list['in']['documents']">
                                            <td>{{getTypeDocument(r.typedocument)}}</td>
                                            <td class="text-right">{{r.total | currency:"&euro;"}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="">
                                        <tr>
                                            <td><b>TOTALE</b></td>
                                            <td class="text-right"><b>{{list['in']['total_documents'] | currency:"&euro;"}}</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card">
                            <div class="card-header bg-danger">
                                <div class="row">
                                    <div class="col-6">
                                        <h5 >Uscite</h5>
                                    </div>
                                    <div class="col-6">
                                        <h5 class="text-right">-{{list['total_out'] | currency:"&euro;"}}</h5>
                                    </div>
                                </div>
                            </div>
                            <div class="card-body bg-light" *ngIf="list['out']">
                                <table class="table border table-nowrap  table-sm table-head-fixed table-hover">
                                    <thead class="alert-danger">
                                        <th>Metodo di pagamento</th>
                                        <th class="text-right">Importo</th>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center" *ngIf="list['out']['methodpayment'].length==0">
                                            <td colspan="2"><i>nessun metodo di pagamento</i></td>
                                        </tr>
                                        <tr *ngFor="let r of list['out']['methodpayment']">
                                            <td>{{r.method_payment}}</td>
                                            <td class="text-right">{{r.total | currency:"&euro;"}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="">
                                        <tr>
                                            <td><b>TOTALE</b></td>
                                            <td class="text-right"><b>{{list['out']['total_methodpayment'] | currency:"&euro;"}}</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                                <br/>
                                <table class="table border table-nowrap  table-sm table-head-fixed table-hover">
                                    <thead class="alert-danger">
                                        <th>Documento</th>
                                        <th class="text-right">Importo</th>
                                    </thead>
                                    <tbody>
                                        <tr class="text-center py-2" *ngIf="list['out']['documents'].length==0">
                                            <td colspan="2"><i>nessun documento</i></td>
                                        </tr>
                                        <tr *ngFor="let r of list['out']['documents']">
                                            <td>{{getTypeDocument(r.typedocument)}}</td>
                                            <td class="text-right">{{r.total | currency:"&euro;"}}</td>
                                        </tr>
                                    </tbody>
                                    <tfoot class="">
                                        <tr>
                                            <td><b>TOTALE</b></td>
                                            <td class="text-right"><b>{{list['out']['total_documents'] | currency:"&euro;"}}</b></td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>                
                <div class="row mt-2">
                    <div class="col-4 d-none d-lg-block" *ngIf="list['total']==0"></div>
                    <div class="col-8 d-none d-lg-block" *ngIf="list['total']<0"></div>
                    <div class="col-12 col-lg-4">
                        <div class="info-box bg-light animation__shake">
                            <span class="info-box-icon bg-gray elevation-1" [class.bg-success]="list['total']>0" [class.bg-danger]="list['total']<0">
                                <i class="fas fa-euro-sign"></i></span>
                            <h4 class="info-box-content mb-0">
                                <span>Saldo: <b  [class.text-danger]="list['total']<0">{{list['total'] | currency:"&euro;"}}</b></span>
                            </h4>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</app-windowlist>