<section class="pt-1">
<div class="dropdown dropleft show" *ngIf="funs.length>0 || customfunctions.length>0">
    <button class="btn btn-xs float-right mt-1" type="button" title="Altri comandi"
        id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-ellipsis-v"></i></button>
    <div class="dropdown-menu p-0 {{currentUser}}" aria-labelledby="dropdownMenuLink">        
        <ng-container *ngFor="let f of funs">
            <div *ngIf="!f['value']" class="text-primary border-bottom text-xs text-uppercase px-2">{{f['name']}}</div>
            <ng-container *ngIf="f['value']">
                <ng-container *ngIf="!f['role'] || currentUser.role=='superuser' || currentUser.role==f['role']" >
                    <ng-container *ngIf="!f['module'] || isModuleEnabled(f['module'])">
                        <button class="dropdown-item text-xs px-2" (click)="model[f['value']]()">
                            {{f['name']}}
                            <i *ngIf="f['role']" class="fa-user-tie fas text-gray float-right help" title="Solo utenti autorizzati"></i>
                        </button>
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-container>
        <hr *ngIf="customfunctions.length>0" class="m-0">
        <button class="dropdown-item text-xs px-2" *ngFor="let cf of customfunctions" (click)="executeCustomFunction(cf)">
            {{cf['name']}}
            <i class="fa-edit far text-gray float-right help" title="Funzione personalizzata"></i>
        </button>
    </div>
</div>
<button  title="Cestina" *ngIf="model.filter_status.value == '1'" (click)="model.trash()" [disabled]="model.form.value.id.length == 0" class="btn btn-danger btn-xs float-right mt-1 ml-1"><i class="far fa-trash-alt"></i></button>
<button  title="Elimina" *ngIf="model.filter_status.value == '2'" (click)="model.delete()" [disabled]="model.form.value.id.length == 0" class="btn btn-danger btn-xs float-right mt-1 ml-1"><i class="fas fa-trash"></i></button>
<button  title="Ripristina" *ngIf="model.filter_status.value == '2' || model.filter_status.value == '3'" (click)="model.undo()" [disabled]="model.form.value.id.length == 0" class="btn btn-secondary btn-xs float-right mt-1 ml-1"><i class="fas fa-undo"></i></button>
<button  title="Archivia" *ngIf="model.filter_status.value != '3'" (click)="model.archive()" [disabled]="model.form.value.id.length == 0" class="btn btn-secondary btn-xs float-right mt-1 ml-1"><i class="fas fa-archive text-warning"></i></button>
<button  title="Esporta" (click)="model.export()" class="btn btn-warning btn-xs float-right mt-1 ml-1"><i class="fas fa-print"></i></button>
<button *ngIf="addButton" title="Aggiungi" (click)="model.openDetail(null,openDetailExtra)" class="btn btn-success btn-xs float-right mt-1 ml-1"><i class="fas fa-plus"></i></button>
</section>