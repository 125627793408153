import { Component, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { Product } from '../../db/product';
import { ProductsService } from '../../services/products.service';

@Component({
  selector: 'app-keyboard',
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.css']
})
export class KeyboardComponent implements OnInit {
  @HostListener('window:keydown', ['$event'])
    onKeyDown(event) {
      
      switch(event.keyCode){
        case 8: //Back Space
         this.backspace();
            
          break;

        case 13: //enter
          this.confirm();
          break;
        default:

          let number=parseInt(String.fromCharCode(event.keyCode))
          
          if(number>-1){
            this.keyNumber(number);
          }
            
          break;
      }
     

     
      
    }


  @Input()
  id_rep="";

  @Output()
  data:EventEmitter<any>=new EventEmitter<any>();


  @Output()
  onDigit:EventEmitter<any>=new EventEmitter<any>();


  @Input()
  showOperations=true;

  @Input()
  showFunctions=true;

  @Input()
  classname="";

  digits:string="";
  number:number=0;
  display:string="";
  moltiplicator=1;
  moltiplicator_string="";
  percentual=0;
  type=1; // 1 = prezzo 2 = EAN

  state=1; //1 = digitazione numeri 2 = digitazione moltiplicatore 3= digitazione percentuale
  reps=[];
  product:Product;


  constructor(
    private productsService:ProductsService=null
  ) { }

  ngOnInit(): void {


  }

  ngOnChanges(){
    //definisci i prodotti per reparto
    if(this.id_rep.toString()!=""){
      let ids_products=this.id_rep.toString().split(",");
      console.log(ids_products);
      if(ids_products.length>0){
        this.productsService.getProducts(ids_products).subscribe((items)=>{
          this.reps=items;
          
          if(this.reps.length>0)
            this.product=this.reps[0];
        });
      }
    }

  }

  backspace(){

    this.beep();

    if(this.state==1) {

      if(this.digits.length<=1) {
        this.number=0;
        this.digits="";
        this.display="";
        return;
      }

        this.digits=this.digits.substring(0,this.digits.length-1);
        this.number=parseFloat(this.digits)/100;

      if(this.digits.length>12){
        this.display=this.digits; //EAN13
        this.type=2;
      }else{
        this.type=1;
        this.display=this.number.toFixed(2); // valuta
      }

    }

    if(this.state==2){
      this.moltiplicator=1;
      this.moltiplicator_string="";
      this.state=1;
    }

    if(this.state==3){
      this.percentual=0;
      this.state=1;
    }

  }


  keyNumber(number){

    this.beep();

    if(this.state==1){
      this.digits=this.digits+number.toString();
      this.number=parseFloat(this.digits)/100;

      if(this.digits.length>12){
        this.display=this.digits; //EAN13
        this.type=2;
        
      }else{
        this.type=1;
        this.display=this.number.toFixed(2); // valuta
      }

    }
   
    if(this.state==2){
      
      // let moltiplicator_string=this.moltiplicator==0?"":this.moltiplicator.toString();
      this.moltiplicator_string=this.moltiplicator_string+number.toString();
      
      this.moltiplicator=parseInt(this.moltiplicator_string);
      if(this.moltiplicator<1)
        this.moltiplicator=1;
    }

    if(this.state==3){
      let percentual_string=this.percentual==0?"":this.percentual.toString();
      percentual_string=percentual_string+number.toString();
      
      this.percentual=parseInt(percentual_string);
    }

    this.onDigit.emit(this.display);
  }


  beep(){

    let audio = new Audio();
    audio.src = "../assets/beep.wav";
    audio.load();
    audio.play();

  }


  clear(){
   
    this.beep();

      this.display="";
      this.digits="";
      this.number=0;
      this.moltiplicator=1;
      this.moltiplicator_string="";
      this.percentual=0;
      this.type=1;
      this.state=1;


    this.onDigit.emit(this.display);
  }

  moltiplicate(){

    this.beep();

    this.state=2;
    this.moltiplicator=0;
    this.onDigit.emit();
  }


  percent(){
    
    this.beep();

    this.state=3;
    this.onDigit.emit();
  }



  auxFunc(p:Product){

    this.beep();

    let product=new Product();
    Object.assign(product,p);

    //verifica se si sta trattando di una voce di sconto
    if(product.firstPrice()<0){
      this.display=(-1*parseFloat(this.display)).toFixed(2);
    }

    this.confirm(product);

  }

  subtotal(){

  }

  confirm(product=null){
    if(this.display!=""){
      this.data.emit({"type":this.type,"value":this.display,"product":product==null?this.product:product,"quantity":this.moltiplicator,"percentual":this.percentual});
      this.clear();
      
    }
    
  }

}
