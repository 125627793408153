import { Component, OnInit, ViewChild, AfterViewInit, Input} from '@angular/core';
import { Model } from 'projects/core/src/include/model';
import { ToastMode } from 'projects/core/src/include/structures';
import { ActivatedRoute } from '@angular/router';
import { Pricelist, Product } from '../../../db/product';
import { Brand } from '../../../db/brand';
import { ProductcategoriesService } from '../../../services/productcategories.service';
import { ProductBrandsService } from '../../../services/productbrands.service';

import { ProductsService } from '../../../services/products.service';
import { ProductCategories } from '../../../db/productcategories';
import { BrandsComponent } from '../brands/brands.component'
import { Globals } from 'projects/core/src/globals';
import { AddressComponent } from '../../address/address.component';
import { UploadService } from 'projects/core/src/lib/upload.service';
import { Image } from '../../../db/image';
import { Helper } from 'projects/core/src/helper';
import { DocumentsDetailsComponent } from '../../documents/documents-details/documents-details.component';
import { CustomfieldsComponent } from 'projects/core/src/common/customfields/customfields.component';
import { TypesService } from '../../../services/types.service';
import { Type } from '../../../db/type';
import { ProductsComponent } from '../products.component';



@Component({
  selector: 'app-products-details',
  templateUrl: './products-details.component.html',
  styleUrls: ['./products-details.component.sass']
})
export class ProductsDetailsComponent extends Model<Product> implements OnInit,AfterViewInit {

  modulename="products";
  categories:ProductCategories[];
  brands:Brand[];
  field_required=['name'];
  fileToUpload: File = null;
  documents=[];
  analitycs=[];
  search_year_analytic=new Date().getFullYear();
  search_type_analytic="1";
  search_id_document_analytic="1,2,12,6";
  total_analytics={"net":0,"tax":0,"gross":0};
  @ViewChild(CustomfieldsComponent) customfields:CustomfieldsComponent;

  @Input()
  name="";
  @Input()
  codeexternal="";
  @Input()
  id_supplier=0;
  @Input()
  tax="";
  @Input()
  netcost=0;
  @Input()
  unit="PZ";

  type_products:Type[];

  filter_typedocument=[1,2,3,4,5,6,7,8,9,10,11,12,13];
  filter_inventory=false
  

  constructor(
    private route1: ActivatedRoute,
    private productsService: ProductsService,
    private productcategoriesService:ProductcategoriesService,
    private productBrandsService:ProductBrandsService,
    private uploadService:UploadService,
    private typesService: TypesService
  ) {super(route1,productsService);  }

  ngOnInit(): void {
    super.ngOnInit.apply(this, arguments); 
    this.record=new Product();
    this.title = 'Prodotto Dettaglio';

    if(this.mode!="modal")
      this.route1.params.subscribe((params) =>{
        this.id = params.id
      } );

    this.record=new Product();
    
    


  }

  
  ngAfterViewInit(){
    

    this.afterGetItem=(()=>{

      


      if(this.id==0){
        this.record.id=0;
        this.record.name=this.name;
        this.record.codeinternal="";
        this.record.codeexternal=this.codeexternal;
        this.record.barcode="";
        this.record.id_supplier=this.id_supplier;
        this.record.supplier=null;
        this.record.id_brand=this.brands[0].id;
        this.record.brand=this.brands[0].name;
        this.record.id_category=this.categories[0].id;
        this.record.netprice=0;
        this.record.netcost=this.netcost;
        this.record.tax=Globals.parameters.get("general").getParam("default_tax");
        this.record.taxcost=this.record.tax;
        this.record.unit=this.unit;
        this.record.weight=0;
        this.record.length=0;
        this.record.height=0;
        this.record.width=0;
        this.record.discountcost1=0;
        this.record.discountcost2=0;
        this.record.discountcost3=0;
        this.record.maxdiscount=100;
        this.record.images=[];
        
       
      }else{
        this.title=(this.record.brand?this.record.brand+" ":"")+this.record.name;
      }

      try{
        this.record.customfieldsList=JSON.parse(this.record.defaultcustomfields);
      }catch{
        this.record.customfieldsList=[];
      }

      try{
        let images=JSON.parse(this.record.images.toString());
        this.record.images=[];
        for(let i of images){
          let img=new Image();
          Object.assign(img,i);
          this.record.images.push(img);
        }
          
      }catch{
        this.record.images=[];
      }


      this.record.checkPricelist();
      
      
      this.customfields.list=this.record.customfieldsList; 
      this.calculateCost();

      if(this.record.id>0)
        this.productsService.getDocuments(this.record.id).subscribe((items)=>{
          this.documents=items;
        });
      

    });

    this.beforeSave=(()=>{
      this.record.defaultcustomfields=JSON.stringify(this.customfields.list);
      return true;
    });

    this.productcategoriesService.getCategories().subscribe((items)=>{
      this.categories=items;
      this.productBrandsService.getBrands(0).subscribe((items)=>{
        this.brands=items;
        this.typesService.getTypes("products").subscribe((items)=>{
          this.type_products=items;
          this.getItem();
        })
      });
      
    });
  }


  openBrandsManager(){
    Globals.modal.showModal(BrandsComponent,[],(instance)=>{
      if(instance){
        this.productBrandsService.getBrands(0).subscribe((items)=>{
          this.brands=items;
        });
      }
    });
  }

  calculateGrossPrice(){
    this.record.grossprice=Math.round(this.record.netprice*(1+this.record.tax/100)*100)/100;
  }

  calculateNetPrice(){
    this.record.netprice=Math.round((this.record.grossprice)/(1+this.record.tax/100)*100)/100;
  }

  addPricelist(){
    let p:Pricelist={} as Pricelist;
    p.id=this.record.pricelist.length;
    p.markup=30;
  
    this.calculateMarkup2Price(p);
    
    this.record.pricelist.push(p);

  }

  calculateMarkup2Price(p:Pricelist){
    p.price=this.record.netcost*(1-this.record.discountcost1/100)*(1-this.record.discountcost2/100)*(1-this.record.discountcost3/100)*(1+p.markup/100);
    p.grossprice=p.price*(1+this.record.tax/100);
  }

  calculatePrice2Markup(p:Pricelist){
    let netcost=this.record.netcost*(1-this.record.discountcost1/100)*(1-this.record.discountcost2/100)*(1-this.record.discountcost3/100);
    p.markup=(p.price-netcost)/netcost*100;
    p.grossprice=p.price*(1+this.record.tax/100);
  }

  calculatePriceGross2Markup(p:Pricelist){
    let netcost=this.record.netcost*(1-this.record.discountcost1/100)*(1-this.record.discountcost2/100)*(1-this.record.discountcost3/100);
   
    p.price=p.grossprice/(1+this.record.tax/100);
    p.markup=(p.price-netcost)/netcost*100;
    
  }

  calculateCost(){
    this.record.grosscost=this.record.netcost*(1-this.record.discountcost1/100)*(1-this.record.discountcost2/100)*(1-this.record.discountcost3/100)*(1+this.record.taxcost/100);

  }

  calculateCostNet(event){
    let gross=parseFloat(event.target.value);

    this.record.netcost=gross/((1-this.record.discountcost1/100)*(1-this.record.discountcost2/100)*(1-this.record.discountcost3/100)*(1+this.record.taxcost/100));

  }



  removePricelist(p:Pricelist){
    for(let i=0;i<this.record.pricelist.length;i++){
      if(this.record.pricelist[i]==p){
        this.record.pricelist.splice(i,1);
        return;
      }

    }
  }


  selectSupplier(){
    Globals.modal.showModal(AddressComponent,[{"name":"mode","value":"modal"},{"name":"id_type","value":parseInt(this.module.getParam("id_type_supplier"))}],(instance)=>{
      if(instance!=null){
        let r=instance['recordSelected'];
        this.record.supplier=r;
        this.record.id_supplier=r.id;
      }
    });
  }


  handleFileInput(files: FileList) {
    Globals.setLoading(true);
    this.fileToUpload = files.item(0);
    this.uploadService.uploadFile(this.fileToUpload,"products",(result)=>{

      let i:Image=new Image();
      i.file=result;

      this.record.images.push(i);
      Globals.setLoading(false);
     
    },true);
}

removeImage(img:Image){
  for(let i=0;i<this.record.images.length;i++){
    if(img==this.record.images[i]){
      this.record.images.splice(i,1);
      return;
    }

  }
 
}

getTypeDocument(type){
  return Helper.getTypeDocument(type);
}

openDocument(document){
  
  Globals.modal.showModal(DocumentsDetailsComponent,[
    {"name":"mode","value":"modal"},
    {"name":"id","value":document['id']},
  ],()=>{

  });
}

addCorrelation(){
  Globals.modal.showModal(ProductsComponent,[
    {"name":"mode","value":"modal"}
      ],(instance)=>{
        if(instance!=null){
          this.record.correlations.push(instance['recordSelected']);
        }
      }
    )
}

removeCorrelation(r){
  for(let i=0;i< this.record.correlations.length;i++){
    if(this.record.correlations[i]==r){
      this.record.correlations.splice(i,1);
      return;
    }
  }
}


openCorrelation(r){
  Globals.modal.showModal(ProductsDetailsComponent,[
    {"name":"mode","value":"modal"},
    {"name":"id","value":r.id}
    
      ],(instance)=>{
        
      }
    )
}


getAnalytic(){

  this.productsService.getAnalytic(this.record.id,"month",this.search_year_analytic+"-01-01",this.search_year_analytic+"-12-31",this.search_id_document_analytic,this.search_type_analytic).subscribe((result)=>{
    this.analitycs=result;
    this.total_analytics.net=0;
    this.total_analytics.tax=0;
    this.total_analytics.gross=0;
    
    for(let a of this.analitycs){
      this.total_analytics.net=this.total_analytics.net+parseFloat(a.total_net);
      this.total_analytics.tax=this.total_analytics.net+parseFloat(a.total_tax);
      this.total_analytics.gross=this.total_analytics.net+parseFloat(a.total_gross);
      
    }
  });

  setTimeout(()=>{   
    if (this.analitycs && this.analitycs.length==0) {
      Globals.message.showToaster("Nessun elemento trovato!",ToastMode.WARNING);
    }
  },500);
  

}


filterDocument(typedocument){
  
  if(typedocument==0){ //se è su tutti
   
      this.filter_typedocument=[];
  }
  
  //verifica se la tipologia di documento è stata gia assegnata
  let idx=this.filter_typedocument.indexOf(typedocument);
  if(idx>-1){
    this.filter_typedocument.splice(idx,1);
  }else{
    this.filter_typedocument.push(typedocument);
  }
}

check_filterdocument(typedocument){
  let idx=this.filter_typedocument.indexOf(typedocument)
  if(idx>-1)
    return true;
  
  return false;
}


filterInventory(){
  this.filter_inventory=!this.filter_inventory;
  
}

showDocuments(d){
  let r=false;
  if(this.filter_inventory){
    r=d.id_inventory_detail_load>0 || d.id_inventory_detail_unload>0;
    if(r)
      r=this.filter_typedocument.indexOf(d.typedocument)>-1
  }else{
    r=this.filter_typedocument.indexOf(d.typedocument)>-1
  }

  return r;
}

getTotalDocuments(){
  let quantity=0;
  for(let d of this.documents){
    if(d.typedocument!=10){ //escludi i trasferimenti
      if(d.id_inventory_detail_load>0 || d.id_inventory_detail_unload>0){
        
            quantity=quantity+parseFloat(d.quantity);
        

      }
    }
    
  }

  return {"quantity":quantity}
  
}

getTypePricelist(data){
  try{
    if(typeof(data)=="object"){
      return data.id_type;
    }else{
      
      let d=JSON.parse(data);
      if(d.id_type){
        return d.id_type;
      }
   
  }
  }catch{

  }

  return 0;
}

setTypePricelist(id_type,name,data){
  data={
    "id_type":id_type,
    "name":name
  }

  return data;
}

}
