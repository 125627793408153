<div class="row my-1">
    <div *ngIf="id_product==0" class="col-12 col-lg-2 pr-lg-0">
        <app-filtercontainer [title]="'Filtra per date, categorie, magazzini, ...'" >
            <div *ngIf="!user.isBackendUser()" class="m-3">
                <i class="fa-2x fas fa-info text-info mr-3"></i>
                <b>ATTENZIONE</b><br>
                <small>
                    <i>Scegli il <b>Magazzino di riferimento</b> e il tipo di <b>giacenza</b>, per avere la lista dei prodotti presenti.</i>
                </small>
            </div>
            <div class="card small-box p-1 mb-2">
                <div class="input-group input-group-sm border px-1" style="border-radius:0.75rem">
                    <div class="input-group-prepend input-group-prepend-sm">
                        <span style="width: 2rem;" class="pt-2"><small >Fino al</small></span>
                    </div>
                    <input *ngIf="user.isBackendUser()" type="date" class="form-control form-control-sm border-0" [(ngModel)]="filter_date.value2" (change)="getItems()">
                    <div class="input-group-append">
                        <span class="btn pt-2 btn-white" title="Cerca fino a questo giorno" (click)="getItems()"><i class="fas fa-search"></i></span>
                    </div>
                </div>
            </div>
            <!--
            <app-filterdate *ngIf="user.isBackendUser()" [date_type]="100" [class]="'card treeviewlist p-1'" [filter_date]="filter_date" (onSelect)="getItems()"></app-filterdate>
            -->
            <app-treecategories [multiple]="true" [class]="'card p-1'" (id_selected)="filter_id_category.value=$event;getItems()" [showAll]="true" [showCountProduct]="false" [id]="filter_id_category.value" [editButton]="false" #tree [list]="categories"></app-treecategories>
            <ul class="card small-box list-group p-1 mt-2">
                <li class="list-group-item text-truncate pointer" [class.active]="filter_id_inventory.value==''" (click)="filter_id_inventory.value='';getItems()">
                    Tutti i magazzini<span  *ngIf="user.isAdmin()" class="float-right" title="Apri gestione magazzini" (click)="openInventoryManager()"><i class="fa fa-cog"></i></span></li>
                <li class="list-group-item text-truncate pointer" [class.active]="filter_id_inventory.value && filter_id_inventory.value.indexOf(i.id)>-1" *ngFor="let i of inventories"  (click)="setFilterTypeValue('filter_id_inventory',i.id);" style="line-height: 1">{{i.name}}</li>
                <!--
                <li class="list-group-item" [class.active]="filter_id_inventory.value==i.id" *ngFor="let i of inventories" (click)="filter_id_inventory.value=i.id;getItems()">{{i.name}}</li>
                -->
                <div *ngIf="inventories && inventories.length>2" class="icon"><i class="fas fa-boxes"></i></div>
            </ul>
            <ul class="card small-box list-group p-1 mt-2">
                <li class="list-group-item pointer" [class.active]="filter_stock.mode==0" (click)="filter_stock.mode=0;filter_stock.value='0';filter_stock.valueIsField='false';getItems();">Tutti</li>
                <li class="list-group-item pointer" [class.active]="filter_stock.mode==6" (click)="filter_stock.mode=6;filter_stock.value='0';filter_stock.valueIsField='false';getItems();">Giacenza positiva</li>
                <li class="list-group-item pointer" [class.active]="filter_stock.mode==7 && filter_stock.value=='0'" (click)="filter_stock.mode=7;filter_stock.value='0';filter_stock.valueIsField='false';getItems();">Giacenza negativa</li>
                <li class="list-group-item pointer" [class.active]="filter_stock.mode==1" (click)="filter_stock.mode=1;filter_stock.value='0';filter_stock.valueIsField='false';getItems();">Giacenza nulla</li>
                <li class="list-group-item pointer" [class.active]="filter_stock.mode==7 && filter_stock.value=='pm.minstock'" (click)="filter_stock.mode=7;filter_stock.value='pm.minstock';filter_stock.valueIsField='true';getItems();">Giacenza da riordinare</li>
                <div class="icon"><i class="fas fa-cubes"></i></div> 
            </ul>
            <ul class="card small-box list-group p-1 my-2" *ngIf="user.isBackendUser()">
                <!-- <input type="date" class="form-control form-control-sm mb-1" name="filter_date_stock" [(ngModel)]="filter_date_stock.value"> -->
                <li class="list-group-item pointer" [class.active]="filter_date_stock.mode==0" (click)="filter_date_stock.mode=0;getItems();">Tutti</li>
                <li class="list-group-item pointer" [class.active]="filter_date_stock.mode==7" (click)="filter_date_stock.mode=7">
                    Movimentati prima del...<i *ngIf="filter_date_stock.mode!=7" class="fas fa-chevron-down float-right"></i>
                    <ng-container *ngIf="filter_date_stock.mode==7">
                        <input type="date" class="form-control form-control-sm" name="filter_date_stock" (change)="getItems();" [(ngModel)]="filter_date_stock.value">
                    </ng-container>
                </li>
                <li class="list-group-item pointer" [class.active]="filter_date_stock.mode==6" (click)="filter_date_stock.mode=6">
                    Movimentati dopo il...<i *ngIf="filter_date_stock.mode!=6" class="fas fa-chevron-down float-right"></i>
                    <ng-container *ngIf="filter_date_stock.mode==6">
                        <br><input type="date" class="form-control form-control-sm" name="filter_date_stock" (change)="getItems();" [(ngModel)]="filter_date_stock.value">
                    </ng-container>
                </li>
                <li class="list-group-item pointer" [class.active]="" (click)="filter_date_stock.mode=6;filter_date_stock.value=current_year+'-01-01';getItems();">Movimentati in questo anno</li>
                <div class="icon"><i class="fas fa-truck-loading"></i></div>
            </ul>
        </app-filtercontainer>
    </div>
    <div [class.col-lg-10]="id_product==0" [class.col-12]="id_product>0">
        <div class="card card-outline">
            <div class="p-0">
                <div class="row" *ngIf="id_product==0">
                    <div class="col-lg-8">
                        <div class="input-group input-group-info input-group-sm p-1">
                            <input type="text" #table_search name="table_search" class="form-control form-control-sm" placeholder="cerca per marca, modello prodotto..." [(ngModel)]="filter_search.value" (keyup.enter)="updateList()">
                            <div class="input-group-append ">
                                <button type="submit" class="btn btn-default text-primary"  title="avvia ricerca" (click)="getItems()"><i class="fas fa-search"></i></button>
                                <button class="btn btn-default text-danger" *ngIf="filter_search.value!=''"  title="annulla ricerca" (click)="filter_search.value='';updateList();"><span id="searchclear" class="fas fa-times"></span></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-6 col-lg-2" *ngIf="user.isBackendUser()">
                        <div class="input-group input-group-sm p-1" >
                            <select class="form-control form-control-sm" name="filter_product_id_type" [(ngModel)]="filter_product_id_type.value" (ngModelChange)="updateList()" [class.alert-info]="filter_product_id_type.value">
                                <option value="">Tutte le tipologie</option>
                                <option *ngFor=" let r of type_products" [ngValue]="r.id">{{r.name}}</option>
                            </select>
                        </div>
                    </div>
                    <!--<div class="col-lg-4 pr-0">
                        <div class="input-group input-group-sm" >
                            <select class="custom-select" name="filter_id_category" [(ngModel)]="filter_id_category.value" (ngModelChange)="updateList()">
                                <option value="">Tutte le categorie</option>
                                <option *ngFor="let t of categories" [ngValue]="t.id">{{t.name}}</option>
                            </select>
                        </div>
                    </div>-->
                    <div class="col-6 col-lg-2 pl-lg-0" *ngIf="user.isBackendUser()">
                        <div class="mx-1">
                            <app-windowlistbuttons 
                            [model]="this" 
                            [addButton]="false" 
                            [table]="'inventories'" 
                            [funs]="[]"
                            ></app-windowlistbuttons>
                        </div>
                        <!-- <button *ngIf="filter_status.value == '1'" (click)="trash()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mx-1 mt-1"><i class="far fa-trash-alt"></i></button>
                        <button *ngIf="filter_status.value == '2'" (click)="delete()" [disabled]="form.value.id.length == 0" class="btn btn-danger btn-xs float-right mx-1 mt-1"><i class="fas fa-trash"></i></button>
                        <button *ngIf="filter_status.value == '2'" (click)="undo()" [disabled]="form.value.id.length == 0" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-undo"></i></button>
                        <button  (click)="export()" class="btn btn-secondary btn-xs float-right ml-1 mt-1"><i class="fas fa-file-export"></i></button> -->
                        <!--<button class="btn btn-success btn-xs float-right m-1" (click)="fastout()"><i class="fas fa-plus"></i></button>-->
                    </div>
                </div>

                <div *ngIf="!list || list.length==0" class="m-5 text-center text-secondary">
                    <i class="icon fas fa-info-circle mr-2"></i><i>{{messageStatus}}</i>

                </div>
                <form *ngIf="list && list.length>0" [formGroup]="form">
                    <table class="table table-nowrap table-striped table-sm table-head-fixed table-hover m-0 text-sm">
                        <thead class="bg-light">
                            <th>
                                <input #checkboxselectall  type="checkbox"  title="Seleziona tutti"
                                (change)="onChangeAll($event.target.checked)" />
                            </th>
                            <th>
                                Prodotto
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('pm.name')">
                                    <i class="fas fa-sort {{classOrdering('pm.name')}}"  ></i>
                                </button>
                            </th>
                            <th class="d-none d-lg-table-cell text-center">
                                Codici
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('ps.stock')">
                                    <i class="fas fa-sort {{classOrdering('pm.codeinternal')}}"  ></i>
                                </button>
                            </th>
                            <th class="d-none d-lg-table-cell text-center">Categoria
                                <button type="button" class="btn btn-tool" (click)="switchOrdering('pc.name')">
                                    <i class="fas fa-sort {{classOrdering('pc.name')}}"  ></i>
                                </button>
                            </th>
                            <th class="text-center">Giacenza</th>
                            <th class="text-right" *ngIf="user.isBackendUser()">Valore</th>
                            <th class="d-none d-lg-table-cell text-center" *ngIf="user.isBackendUser()">Tipologia</th>
                            <th></th>
                        </thead>
                        <tbody>                        
                            <!-- elemento ripetuto -->
                                <tr  *ngFor="let r of list">
                                    <td>
                                        <input type="checkbox" #checkrecord id_record="{{r.id}}"  title="{{r.id}}" 
                                        (change)="onChange(r.id, $event.target.checked)" [checked]="checkedAll" />
                                    </td>
                                    <td style="max-width:10rem">
                                        {{r.brand}} <b>{{r.name}}</b>
                                        <i class="fa fa-pencil-alt ml-2 pointer" *ngIf="user.isBackendUser()" title="Dettagli" (click)="openProduct(r)"></i>
                                        <br>
                                        <ng-container *ngIf="r.serials">
                                            <ng-container  *ngFor="let s of r.serials">
                                                <ng-container *ngIf="s.avaible>0">
                                                    <span title="Codici Seriali" class="badge border mr-1 help">{{s.sn}}</span>
                                                </ng-container>
                                            </ng-container>
                                        </ng-container>
                                    </td>
                                    <td class="d-none d-lg-table-cell text-center">
                                        <ng-container *ngIf="r.codeinternal">
                                            <span title="Codice Articolo" class="badge border help">{{r.codeinternal}}</span><br/>
                                        </ng-container>
                                        <span title="Codice Fornitore" class="badge bg-gray help">{{r.codeexternal}}</span>
                                    </td>                                    
                                    <td class="d-none d-lg-table-cell text-center">{{r.category}}</td>
                                    <td class="text-center" [class.text-success]="r.stock>0" [class.text-danger]="r.stock < 1">
                                        <b>{{r.stock}} </b><small>{{r.unit}}</small><br>
                                        <i *ngIf="r.stock<r.minstock"  title="Quantità inferiore allo stock minimo ({{r.minstock}} {{r.unit}})" class="text-warning fas fa-exclamation-triangle help"></i>
                                    </td>
                                    <td class="text-right" *ngIf="user.isBackendUser()">
                                        {{r.cost | currency:"&euro;"}}
                                    </td>
                                    <td class="d-none d-lg-table-cell text-center" *ngIf="user.isBackendUser()">{{r.type}}</td>
                                     <td>
                                        <button type="button" class="btn btn-primary btn-xs float-right m-1" title="Sposta in un altro magazzino"
                                         (click)="move(r)"><i class="fas fa-arrows-alt-h"></i></button>
                                    </td>
                                </tr>
                        </tbody>
                        <tfoot class="bg-white" *ngIf="user.isBackendUser()">
                            <td colspan="2"><b>TOTALE</b></td>
                            <td colspan="2" class="d-none d-lg-table-cell"></td>
                            <td></td>
                            <td class="text-right"><b>{{total_cost | currency:"&euro;"}}</b></td>
                            <td colspan="2"></td>
                        </tfoot>
                    </table>
                </form>
                <div class="card-footer clearfix py-1">
                    <app-pagination [model]="this" ></app-pagination>
                </div>
            </div>
        </div>
    </div>
</div>