import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmdeadlineComponent } from './confirmdeadline/confirmdeadline.component';
import { ModalModule } from 'projects/core/src/common/modal/modal.module';
import { FormsModule } from '@angular/forms';
import { DeadlinesComponent } from './deadlines.component';
import { WindowlistModule } from 'projects/core/src/common/windowlist/windowlist.module';
import { PaginationModule } from 'projects/core/src/common/pagination/pagination.module';
import { DeadlineDetailsComponent } from './deadline-details/deadline-details.component';
import { DeadlinesRoutingModule } from './deadlines-routing.module';
import { FilterdateModule } from '../../modules/filterdate/filterdate.module';
import { WindowModule } from 'projects/core/src/common/window/window.module';
import { ToolbarmodelModule } from 'projects/core/src/common/toolbarmodel/toolbarmodel.module';
import { ReferenceModule } from '../../modules/reference/reference.module';
import { CustomfieldsModule } from 'projects/core/src/common/customfields/customfields.module';
import { ConfirmdeadlinesComponent } from './confirmdeadlines/confirmdeadlines.component';
import { FinancialaccountModule } from '../../modules/financialaccount/financialaccount.module';
import { TypeModule } from '../../modules/type/type.module';
import { CalendarComponent } from './calendar/calendar.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { ListComponent } from './list/list.component';
import { FiltercontainerModule } from '../../modules/filtercontainer/filtercontainer.module';
import { WindowlistbuttonsModule } from 'projects/core/src/common/windowlistbuttons/windowlistbuttons.module';
import { Globals } from 'projects/core/src/globals';


@NgModule({
  declarations: [DeadlinesComponent,DeadlineDetailsComponent,ConfirmdeadlineComponent, ConfirmdeadlinesComponent, CalendarComponent, ListComponent],
  exports:[DeadlinesComponent,DeadlineDetailsComponent,ConfirmdeadlineComponent,CalendarComponent,ListComponent],
  imports: [
    CommonModule,
    FormsModule,
    ModalModule,
    ReferenceModule,
    CustomfieldsModule,
    WindowlistModule,
    WindowModule,
    ToolbarmodelModule,
    PaginationModule,
    DeadlinesRoutingModule,
    FilterdateModule,
    FinancialaccountModule,
    FiltercontainerModule,
    TypeModule,
    WindowlistbuttonsModule,
    CalendarModule.forRoot({
    provide: DateAdapter,
    useFactory: adapterFactory,
  }),
    

  ]
})
export class DeadlinesModule { 
  constructor(){
    Globals.parameters.loadModulesEvent.subscribe((result)=>{
      Globals.parameters.createParam(
          "deadlines",
          "notificationrules",
          "Notifica automatica scadenze",
          "notificationrules",
          []
      );
    });
  }
  
  
}
