import { Component, OnInit, Input,Output,EventEmitter, ComponentFactoryResolver, ViewContainerRef, ViewChild } from '@angular/core';
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { Extension } from 'projects/core/src/include/parameters';
import { Type } from '../../db/type';
import { InitModule } from '../init/init.module';

@Component({
  selector: 'app-reference',
  templateUrl: './reference.component.html',
  styleUrls: ['./reference.component.css']
})
export class ReferenceComponent implements OnInit {
  @ViewChild('componentHolder', { read: ViewContainerRef }) componentHolder: ViewContainerRef;
  constructor(private componentFactoryResolver: ComponentFactoryResolver) { }
  @Input()
  reference:any=null;

  @Input()
  disableOpen=false;

  @Input()
  minimazed=true;
  
  get references(){
    let a=[];
    if(this.reference){
      if(!Array.isArray(this.reference)){
        try{
        if(this.reference['table_reference']==undefined || this.reference['table_reference']==null){
          this.reference['table_reference']=this.table;
        }
        }catch{
          
        }

        a.push(this.reference);
      } else{
        a=this.reference;
      }
    }

    return a
  }

  @Input()
  mode:string;

  @Input()
  multiple:boolean=false;


  @Input()
  table:string;

  @Input()
  showConnectBtn:boolean=false;

  @Input()
  showDisconnect:boolean=false;

  @Input()
  type_filter:Type;

  @Output()
  recordConnected:EventEmitter<any>=new EventEmitter();

  tables_to_connected=[]

  today = new Date();


  ngOnInit(): void {
    
   
    //this.generateMenu();


  }

  ngOnChanges(){
    if(this.showConnectBtn)
      this.generateMenu();


    
  }

  isModuleEnabled(modulename){
      
    let module:Extension=Globals.parameters.get(modulename);

    if(module){
      //verifica se l'utente può visualizzarlo
      var s=module.permissions.indexOf(Globals.user.role);
      if(module.permissions.indexOf(Globals.user.role)>-1){
        return true;
      }
    }

    return false;
    
  }


  generateMenu(){
    this.tables_to_connected=[];
    let tables=[];

    //prendi i parametri della tipologia passata (se presente)
    if(this.type_filter){
      let type_params:any=this.type_filter.params;
      if(type_params!="" && type_params!=undefined){
        try{
          type_params=JSON.parse(type_params);
          if(type_params['tables']){
            tables=type_params['tables'];
          }
        }catch{
          type_params=[];
        }
      }
    }

    
    this.addToTableToConnect(tables,"Anagrafica","addresses","address");
    this.addToTableToConnect(tables,"Prodotto con seriale","installations","installations");
    this.addToTableToConnect(tables,"Sede","shops","shops");
    this.addToTableToConnect(tables,"Contratto","contracts","contracts");
    this.addToTableToConnect(tables,"Documento in uscita","documents_sell","documents");
    this.addToTableToConnect(tables,"Documento in ingresso","documents_buy","documents");
    this.addToTableToConnect(tables,"Attività","activities","activities");
    this.addToTableToConnect(tables,"Scadenza","deadlines","deadlines");
    this.addToTableToConnect(tables,"Progetto","project","project");
    this.addToTableToConnect(tables,"Annuncio","ads","ads");
    this.addToTableToConnect(tables,"Prenotazione","booking","booking");
    this.addToTableToConnect(tables,"Pratica","dossier","dossier");


    
    //verifica se un riferimento già presente è idoneo all'elenco delle tabelle
    if(tables.length>0)
      if(tables.indexOf(this.reference.table)==-1){
        this.reference=null;
        this.recordConnected.emit(null);
      }
    
    
    
  }

  addToTableToConnect(tables,label,table,modulename){
    if(!this.isModuleEnabled(modulename))
      return;
    
    
    if(tables.length==0){
      this.tables_to_connected.push({"label":label,"table":table});
    }else{
      if(tables.indexOf(table)>-1){
        this.tables_to_connected.push({"label":label,"table":table});
      }

    }

    
  }

  
  connectActivity(type:string){

    var component;
    var aux={};

    

    component=InitModule.getComponent(type);
    switch(type){
      case "documents_sell":
        aux={"name":"type","value":1};
        type="documents";
        break;
      case "documents_buy":
        aux={"name":"type","value":2};
        type="documents";
        break;
    }

    
  
    Globals.modal.showModal(component,[{"name":"mode","value":"modal"},aux],(instance)=>{
      if(instance['recordSelected']){
        instance['recordSelected']['table']=type;
        instance['recordSelected']['table_reference']=type;
        
        if(this.multiple){
          let result=[];
          for(let r of this.references){
            if(r){
              result.push(r);
            }
          }
          
          if(result.length==0){
            this.recordConnected.emit(instance['recordSelected']);
          }else{
            result.push(instance['recordSelected']);
            this.recordConnected.emit(result);
          }
         
        }else{
          this.recordConnected.emit(instance['recordSelected']);
        }
      }
     
    });
  
  }


  removeReference(r){
    if(this.isArray()){
      if(this.reference.length==1){
        this.disconnect();
      }else{
        for(let i=0;i<this.reference.length;i++){
          if(r==this.reference[i]){
            this.reference.splice(i,1);

            if(this.reference.length==1){
              this.recordConnected.emit(this.reference[0]);
            }else{
              this.recordConnected.emit(this.reference);
            }

          }
        }
      }
    }else{
      this.disconnect();
    }
    
  }

  disconnect(){
    this.recordConnected.emit(null);
  }

  static getIdAddress(table,reference){
    if(reference==null)
      return 0;

   


    if(Array.isArray(reference)){
      for(let r of reference){
        if(r){

          if(table!=""){
            if(!r['table_reference'])
              r['table_reference']=table;
          }

          return ReferenceComponent._getIdAddress(r['table_reference'],r);
        }
      }
    }else{
      if(table!=""){
        if(!reference['table_reference'])
          reference['table_reference']=table;
      }

      return ReferenceComponent._getIdAddress(reference['table_reference'],reference);
    }
    return 0;
  }

  static _getIdAddress(table,reference){
    switch(table){
      case "installations":
        return reference.shop.id_address;
       
      case "addresses":
         return reference.id;
        
      case "contracts":
        if(reference.id_address)
          return reference.id_address
        else
          return reference.shop.id_address;
       
      case "shops":
        return reference.id_address;
      case "documents":
        return reference.id_address;
      case "activities":
        return this.getIdAddress(reference.table,reference.reference);
      case "deadlines":
          return this.getIdAddress(reference.table,reference.reference);
      case "dossier":
        return reference.id_address;
      case "ads":
        return 0;
    }

    return 0;
  }


  openReference(record,table){

    if(this.disableOpen)
      return;
    let component=InitModule.getComponent(table);
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
    
    let componentRef = this.componentHolder.createComponent(componentFactory);

    try{
      componentRef.instance['openDetail'](record);
    }
    catch(e) {
      console.log("⚠️ " + e);
      alert("Non è possibile visualizzare l'elemento richiesto.");
    }

    componentRef.destroy();

  }

  getTypeDocument(type){
    return Helper.getTypeDocument(type);
  }

  verifyDate(d):boolean{
    if(Date.parse(d)>this.today.getTime())
      return true;
    else
    return false;
  }

  isArray(){
    if(this.reference==null)
      return false;
    return Array.isArray(this.reference);
  }

}
