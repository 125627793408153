import { Component, Input, HostListener, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ModelList } from 'projects/core/src/include/modellist'
import { Globals } from 'projects/core/src/globals';
import { Helper } from 'projects/core/src/helper';
import { InventoriesDetails } from 'projects/c1-backend/src/app/db/inventories';
import { InventoriesDetailsService } from 'projects/c1-backend/src/app/services/inventoriesdetails.service';
import { Product } from 'projects/c1-backend/src/app/db/product';
import { DocumentsService } from 'projects/c1-backend/src/app/services/documents.service';
import { MethodpaymentComponent } from '../methodpayment/methodpayment.component';
import { CashflowService } from 'projects/c1-backend/src/app/services/cashflow.service';
import { Extension } from 'projects/core/src/include/parameters';
import { ToastMode } from 'projects/core/src/include/structures';
import { DocumentDetail, DocumentMode, Documents } from 'projects/c1-backend/src/app/db/documents';
import { DocumentsDetailsComponent } from 'projects/c1-backend/src/app/views/documents/documents-details/documents-details.component';
import { LoadserialsComponent } from 'projects/c1-backend/src/app/views/inventories/loadserials/loadserials.component';
import { DocumentlistComponent } from '../../documents/documentlist/documentlist.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {WebSocketSubject } from "rxjs/webSocket";
import { ProductsService } from '../../../services/products.service';
import { ReceiptComponent } from '../../../modules/receipt/receipt.component';
import { GoodsComponent } from '../../../modules/goods/goods.component';
import { AppComponent } from '../../../app.component';
import { DocumentsComponent } from '../../documents/documents.component';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-sales',
  templateUrl: './sales.component.html',
  styleUrls: ['./sales.component.css']
})

export class SalesComponent extends ModelList<InventoriesDetails> implements OnInit {
  CashRegisterWebSocket: WebSocketSubject<any>;
  
  @Input()
  list:InventoriesDetails[]=[];
  
  @Input()
  id_address:number=0;

  @Input()
  id_table=0;

  @Input()
  table:string="";

  @Input()
  ids_products=[];

  @Input()
  showKeyboard=true;
  
  net_total:number=0;
  tax_total:number=0;
  discount_total:number=0;
  total:number=0;
  total_paid:number=0;
  quantity:number=1;
  id_inventory:number=0; //inventario dell'utente corrente
  id_document_imported:number=0;
  module:Extension=new Extension(); 
  documentImported:any;
  description_unload:string="";
  rt="";
  modalShow:boolean=false;

  @ViewChild("modalUnload")
  modalUnload:TemplateRef<any>;
  modalUnloadRef;

  @ViewChild("modalPrint")
  modalPrint:TemplateRef<any>
  modalPrintRef;

  @ViewChild("modalConfirmReceipt")
  modalConfirmReceipt:TemplateRef<any>
  modalConfirmReceiptRef;  
  
  @ViewChild("modalGenerateDocument")
  modalGenerateDocument:TemplateRef<any>
  modalGenerateDocumentRef;
  
  @ViewChild(GoodsComponent)
  goods:GoodsComponent;

  receiptUnloadList=[];
  receiptMethodPayment="";
  receiptDocument;


  id_product_rep="";  


  get not_in_stock(){
    if(this.module.getParam("force_unload"))
      return false;
    const r=this.list.filter(x=>x['not_in_stock']==true && x['product']['stockable']);
    return r.length>0?true:false;
  }

  get not_in_this_stock(){
    if(this.module.getParam("force_unload"))
      return false;
    const r=this.list.filter(x=>x['not_in_this_stock']==true && x['product']['stockable']);
    return r.length>0?true:false;
  }

  c1connector_enable=false;
  constructor(
    private inventoriesDetailsService:InventoriesDetailsService,
    private fb1:UntypedFormBuilder,
    private documentsService:DocumentsService,
    private cashflowService:CashflowService,
    private modalService:NgbModal,
    private productsService:ProductsService
    
    ) 
    { 
      super(inventoriesDetailsService,fb1);
      this.module=Globals.parameters.get("salesbackend");
      this.id_inventory=Globals.user.getParam("id_inventory",0);
      this.showKeyboard=this.module.getParam("showKeyboard",true);
      
      this.c1connector_enable=this.module.getParam("c1connector_enable_receipt",false);

      if(this.c1connector_enable){
        //collega al C1Connector
        Globals.c1connector.onMessageReceived.subscribe((msg)=>{
          if(msg['cmd']=="rt_connected"){
            this.rt=msg['value'];
          }
          
          
        });

        Globals.c1connector.onDisconnected.subscribe(()=>{
          this.rt="";
        });


        if(Globals.c1connector.connected){
        this.connectCashRegister();
        }
      }


      //this.connectMqtt();
      
    }

    connectCashRegister(){
       //invia il messaggio per il registratore di cassa
       let m={}
       m['cmd']="getRT";
       Globals.c1connector.sendMessage(m);
    }

  

  ngOnInit(): void {
    this.title="Vendita al banco";    


    if(this.list.length>0){
      this.calculateTotal();
    }

    if(this.ids_products.length>0){
      //trova i prodotti da inserire nel carrello
      this.productsService.getProducts(this.ids_products).subscribe((items)=>{
        for(let p of items){
          let product=new Product();
          Object.assign(product,p);
          this.add(product);
        }
      });
    }

    this.id_product_rep=this.module.getParam("id_product_rep","");

   
   
  }

  ngOnChanges():void{
    if(this.list.length>0){
      this.calculateTotal();
    }
  }

  /******** CARRELLO *****/
  add(product:Product){

    

    //verifica se il prodotto deve avere un seriale
    if(product.has_serials){
      if(product.sn=="" || product.sn==undefined){
        Globals.modal.showModal(LoadserialsComponent,[{"name":"type","value":2},{"name":"quantity","value":1},{"name":"id_product","value":product.id},{"name":"serials","value":product.serials}],(instance)=>{
          if(instance!=null){
            for(let s of instance['serials']){
              if(s.selected==true){
                product.sn=s.sn;
                this._addProduct(product);
              }
            }

            
          }
        });
        return;
      }
    }
    this._addProduct(product);
    
  }

  _addProduct(product:Product){

    //verifica se il prodotto è stato già aggiunto
    for(let i=0;i<this.list.length;i++){
      if(this.list[i].id_product==product.id){
        //se ha un numero seriale, verifica che non sia lo stesso
        if(product.has_serials){
          if(this.list[i].sn==product.sn){
            return;
          }
        }else{
          this.list[i]['quantity']=this.list[i]['quantity']+this.quantity;
          //verifica se vi è disponibilità in questo magazzino
          if(this.verifyAvaible(product,this.list[i]['quantity'],this.list[i])){
            
            this.calculateTotal();
            return;
          }else{
            this.list[i]['no_avaible']=true;
            return;
          }
        }
      }
    }

    let d=this.createRowInventories(product,this.quantity,product.getPrice(this.quantity,2));
    
    this.list.push(d);
    this.calculateTotal();
  }

  createRowInventories(product:Product,quantity=1,price=0,discount=0){
    let d:InventoriesDetails={} as InventoriesDetails;
    d.id=0;
    d.product=product;
    d.sn=product.sn;
    d.id_product=product.id;
    d.code=product.codeinternal;
    d.quantity=quantity;
    d.netprice=price;
    d.tax=product.tax;
    d.type=2; //scarico
    d.unit=product.unit;
    d.description=(product.brand?product.brand+" ":"")+product.name;
    d.gross=d.netprice*(1+d.tax/100);
    d.discount1=discount;
    d.discount2=0;
    d.discount3=0;
    
    d.id_inventory=Globals.user['shop']?Globals.user['shop'].id_inventory:Globals.parameters.get("inventories").getParam("default_inventory");
    d.date=Helper.convertDateControl();
    let t=new Date();
    d.time=t.getHours().toString()+":"+t.getMinutes().toString();
    d.id_user=Globals.user.id;

    if(!this.verifyAvaible(product,this.quantity,d)){
      d['no_avaible']=true;

    }

    return d;
  }

  verifyAvaible(product:Product,quantity:number,record:InventoriesDetails){
    record['is_quantizable']=true;
    record['no_avaible']=false;
    record['not_in_stock']=false;
    record['not_in_this_stock']=false;

    //verifica se il prodotto ha le quantità necessarie in tutti i magazzini
    if(product.stock==0 && product.stockable){
      record['is_quantizable']=false;
      record['no_avaible']=true;

      record['not_in_stock']=true;
      return false;
    }

    //verifica che il prodotto abbia le quantità necessarie in questo magazzino

    for(let s of product.stocks){
      if(s.id_inventory==this.id_inventory){
        if(s.stock<quantity){
          record['no_avaible']=true;

          record['not_in_this_stock']=true;
          record['max_quantity']=record.product.getStockInventory(this.id_inventory);
          if(record.product.stock>record['max_quantity']){
            record['in_other_stock']=true;
          }
          return false;
        }
      }
    }

    return true;
  }

  remove(product:Product){
    for(let i=0;i<this.list.length;i++){
      if(this.list[i].id_product==product.id){
        this.list.splice(i,1);
        this.calculateTotal();
        return;
      }

    }
  }

 

  calculateTotal(){
    this.total=0;
    this.net_total=0;
    this.tax_total=0;
    this.discount_total=0;
    for(let p of this.list){

      //p.netprice=p.product.getPrice(p.quantity,2);
      this.net_total=this.net_total+(p.netprice*p.quantity)*(1-Helper.getEquivalentDiscount(p.discount1,p.discount2,p.discount3)/100);
      this.discount_total=this.discount_total+(p.netprice*p.quantity)*(Helper.getEquivalentDiscount(p.discount1,p.discount2,p.discount3)/100);
      this.tax_total=this.tax_total+(p.netprice*p.quantity*(p.tax/100));
      this.total=this.total+(p.netprice*p.quantity)*(1-Helper.getEquivalentDiscount(p.discount1,p.discount2,p.discount3)/100)*(1+p.tax/100);
    }

    let item_not_avaible=this.list.filter(x=>x['no_avaible']==true && x['product']['stockable']);
    let total_not_avaible=0;
    for(let i of item_not_avaible){
      total_not_avaible=total_not_avaible+(i.netprice*i.quantity)*(1-Helper.getEquivalentDiscount(i.discount1,i.discount2,i.discount3)/100)*(1+i.tax/100);
    }

    if(this.module.getParam("force_unload")){
      this.total_paid=this.total;
    }else{
      this.total_paid=this.total-total_not_avaible;
    }
      
  }

  cancel(){
    this['modalWindow'].dismiss("cancel");
  }


  openUnloadDescription(){
    if(this.list.length==0){
      Globals.message.showToaster("Indicare almeno un prodotto nella lista",ToastMode.WARNING);
      return;
    }
    this.modalUnloadRef=this.modalService.open(this.modalUnload);
  }

  closeUnloadDescription(){
    this.modalUnloadRef.close();
  }

  unload(){
    if(this.list.length==0){
      Globals.message.showToaster("Inserire almeno un voce di bene nella lista",ToastMode.DANGER);
      return;
    }

    //elimina tutte le voci che non si possono scaricare da questo magazzino
    let toUnload=[];

    if(this.module.getParam("force_unload"))
      toUnload=this.list;
    else{
      toUnload=this.list.filter(x=>(!x['no_avaible']));
    }
   
    //inserisci la descrizione dello scarico
    for(let r of this.list)
      r.note=this.description_unload;

    if(toUnload.length>0){
      this.inventoriesDetailsService.saveMultiple(toUnload,()=>{
          this.reset();
      });
    }else{
      Globals.message.showToaster("Nessun elemento da scaricare",ToastMode.WARNING);
      this.reset();
    }

    
  }

  //scontrino fiscale
  receipt(){
    if(this.list.length==0){
      Globals.message.showToaster("Inserire almeno un voce di bene nella lista",ToastMode.DANGER);
      return;
    }
   
    this.createOrderClient(()=>{
      //crea la voce contabile
      if(this.total_paid==0){
        if(!confirm("Nessun elemento da pagare. Continuare lo stesso?")){
         
          return;
        }
      }
      if(this.module.getParam("force_unload"))
        this.receiptUnloadList=this.list;
      else
        this.receiptUnloadList=this.list.filter(x=>(!x['no_avaible']));
      

      //azzera gli id per sicurezza
      for(let i=0;i<this.receiptUnloadList.length;i++)
        this.receiptUnloadList[i]['id']=0;

      if(this.receiptUnloadList.length>0){

        this.modalShow=true;
        Globals.modal.showModal(MethodpaymentComponent,[{"name":"total","value":this.total_paid}],(instance)=>{
          if(instance==null){
            this.modalShow=false;
            return;
          }
          this.receiptMethodPayment=instance["listMethods"];
          
          //avvia il pagamento
          this.receiptDocument={};
          this.receiptDocument['id_user']=Globals.user.id;
          this.receiptDocument['RT']=this.rt;
          this.receiptDocument['details']=this.receiptUnloadList;
          this.receiptDocument['id_document_linked']=this.id_document_imported;
          this.receiptDocument['enable_inventory']=true;
          this.receiptDocument['methodpayment']=this.receiptMethodPayment;
          this.receiptDocument['origin']="SALES";
          this.receiptDocument['id_address']=this.id_address;
          this.receiptDocument['id_table']=this.id_table;
          this.receiptDocument['table']=this.table;
          this.receiptDocument['amount']=this.total_paid;
          this.receiptDocument['created_from']=Globals.user['shop']?Globals.user['shop'].id:0;
          this.receiptDocument['id_type']=1;
          this.receiptDocument['typedocument']=6;

          
          
      

          for(let i of this.list)
            i.state=2; //conferma 
          

          //invia al registratore di cassa
          let modalShow = true;
          if(this.c1connector_enable){
            Globals.modal.showModal(ReceiptComponent,[
              {"name":"mode","value":"modal"},
              {"name":"document","value":this.receiptDocument},
              {"name":"methodpayments","value":this.receiptMethodPayment}
              
            ],(instance)=>{
              if(instance){
                this.reset();
                this.modalShow=false;
              }
            })
          } else{
            this.receiptDocument['paid']=this.total_paid;
            this.receiptDocument['noCashflow']=false;
            this.receiptDocument['state']=2;
            this.documentsService.processReceipt(this.receiptDocument,(response)=>{
              console.log(response);
              if(response && response.status){
                this.reset();
              }else{

                Globals.modal.showConfirm("Errore nell'emissione dello scontrino","Errore nella comunicazione con il POS. Riprovare?",(result)=>{
                  this.receipt();
                },"Riprova ad inviare al POS","Salva il documento","success",()=>{
                  this.documentsService.save(this.receiptDocument,()=>{
                    this.reset();
                  });
                });

                
              }
              
              
            });
          }

         /* if(this.rt){ //se il registratore di cassa è collegato
            this.handleDocument();
          }else{
            this.modalConfirmReceiptRef=this.modalService.open(this.modalConfirmReceipt)
          }*/

            
          
          
        },"md");
      }else{
          Globals.message.showToaster("Nessun elemento da pagare",ToastMode.WARNING);
          this.reset();
        
      }
    
    })
  
   
    
  }

/*
  handleDocument(){
    this.documentsService.createReceipt(this.receiptDocument,(document)=>{
      this.receiptDocument=document;
      if(this.rt)
        this.sendReceiptToRT(document); //invia lo scontrino al registratore di cassa
      else
        this.confirmReceipt(); //conferma lo scontrino
    });
  }*/

  /*

  sendReceiptToRT(document){
    
    this.modalPrintRef=this.modalService.open(this.modalPrint); //apri la modal di stampa
    //invia lo scontrino al registratore di cassa
    let message:any={};
    message.cmd="receipt";
    

    let value:any={}
    value.id=document['id'];
    value.amount=document['total'];
    value.method_payment=document['methodpayment'];
    value.details=[];

    for(let i of document['details']){
      let detail:any={};
      detail.description=i['description'];
      detail.quantity=i['quantity'];
      detail.tax=i['tax'];
      detail.discount=Helper.getEquivalentDiscount(i['discount1'],i['discount2'],i['discount3']);
      
      detail.price=(Math.round(i['netprice']*100)/100)*(1+i['tax']/100)*(1-Helper.getEquivalentDiscount(i['discount1'],i['discount2'],i['discount3'])/100);
      
      detail.price=(Math.round(detail.price * 100) / 100).toFixed(2);

      value.details.push(detail);
    } 


    message.value=value;
    
    Globals.c1connector.sendMessage(message);
    //this.sendMessage(message);
    //this.CashRegisterWebSocket.next(message);
  }
  */
  
  
  /*
  confirmReceipt(){
      //verifica se si tratta dello stesso id_document
      this.documentsService.confirmReceipt(this.receiptDocument['id']).subscribe((result)=>{
        this.reset();
      });
      
      /*
      //crea la voce di cashflow
      let cashflow:CashFlow=new CashFlow();
      cashflow.id_table=this.receiptDocument['id'];
      cashflow.table="documents";
      cashflow.amount=this.receiptDocument['total'];
      cashflow.paid=this.receiptDocument['total'];
      cashflow.method_payment=this.receiptMethodPayment;
      cashflow.id_user=Globals.user.id;
      cashflow.id_address=this.receiptDocument['id_address'];
      cashflow.id_type=this.module.getParam("id_type_cashflow",0);

      this.cashflowService.save(cashflow,(id_cashflow)=>{
        
        for(let l of this.receiptUnloadList){
          l['id_table']=this.receiptDocument['id'];
          l['table']="documents";
        }

        this.inventoriesDetailsService.saveMultiple(this.receiptUnloadList,()=>{
            this.updateOrderClient(()=>{
              this.reset();
            })
            
        });
        

        //aggiorna lo stato dello scontrino

        this.reset();

      });*/
    

  //}
  

  billing(){
    if(this.list.length==0){
      Globals.message.showToaster("Inserire almeno un articolo nella lista",ToastMode.WARNING);
      return;
    }
    
    this.createOrderClient(()=>{      
      
      if(this.total_paid==0){
        Globals.modal.showConfirm("Ordine Cliente","Non risultano articoli da pagare!",()=>{
          return;
        },"Annulla","Procedi comunque","danger")        
      }
      
      //crea la voce contabile
      for(let i of this.list)
          i.state=2; //conferma 
      
      
      let toUnLoad;
      if(this.module.getParam("force_unload"))
        toUnLoad=this.list;
      else
        toUnLoad=this.list.filter(x=>(!x['no_avaible'] || !x['product']['stockable']));
      

      for(let i of toUnLoad){
        i['id']=0;
      }  
      
      this.modalShow=true;
      Globals.modal.showModal(MethodpaymentComponent,[
        {"name":"total","value":this.total_paid}
      
      
      ],(instance)=>{
        if(instance!=null){

          this.modalShow=false;
          Globals.modal.showModal(DocumentsDetailsComponent,[
            {"name":"mode","value":"modal"},
            {"name":"id","value":0},
            {"name":"details","value":toUnLoad},
            {"name":"autoUnload","value":false},
            {"name":"checkPayment","value":true},
            {"name":"methodpayment","value":instance["listMethods"]},
            {"name":"origin","value":"SALES"},
            {"name":"id_document_linked","value":this.id_document_imported},
            {"name":"id_address","value":this.id_address},
            {"name":"id_table","value":this.id_table},
            {"name":"table","value":this.table},
            {"name":"paid","value":this.total_paid},
            
          ],(instanceBilling)=>{
                if(instanceBilling!=null){
                  this.reset();
                }
              });
            }
            this.modalShow=false;
          },"md");
  
      });
  }

  openOrderClient(details=null,complete=null){

    if(details==null){
      details=this.list;
    }

    Globals.modal.showModal(DocumentsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"type_document","value":7},
      {"name":"details","value":details},
      
    ],(instance)=>{
      if(instance!=null){
        Globals.message.showToaster("Ordine cliente creato",ToastMode.SUCCESS);
        this.reset();
        
        if(complete) complete();
      }
    });
  }


  createOrderClient(complete:Function=null){
    
    if(this.module.getParam("force_unload")){
    
        complete();
      return;
    }


    if(this.not_in_stock || this.not_in_this_stock){ //se non ci sono prodotti, crea un ordine cliente
      let details=[];
      //prendi tutti i prodotti che sono da ordinare
      Object.assign(details,this.list.filter(x=>x['no_avaible']==true)); //clona i valori
      for(let d of details){
        delete d['no_avaible'];
        d['quantityPartial']=0;
      }
      Globals.modal.showModal(DocumentsDetailsComponent,[
        {"name":"mode","value":"modal"},
        {"name":"type_document","value":7},
        {"name":"details","value":details},
        
      ],(instance)=>{
        if(instance!=null){
          Globals.message.showToaster("Ordine cliente creato",ToastMode.SUCCESS);
          if(complete) complete();
        }
      });

    }else{
      //esci
      if(complete) complete();
    }
  }

  updateOrderClient(complete){
    if(this.id_document_imported==0){
      complete();
      return;
    }


    //vai a confermare l'ordine cliente
    let toUnLoad=this.list.filter(x=>(!x['no_avaible']));

    for(let d of this.documentImported.details){
      for(let l of toUnLoad){
        if(d.product.id==l.product.id){
          d.quantityPartial=d.quantityPartial+l.quantity;
        }
      }
    }

    //verifica se ci sono altri elementi da consegnare
    let d=this.documentImported.details.filter(x=>x.quantity!=x.quantityPartial);
    if(d.length>0){
      this.documentImported.state=6; //parziale
    }else{
      this.documentImported.state=2; //completo
    }

    this.documentsService.save(this.documentImported,()=>{
      complete();
    });




  }

  
  reset(askConfirm=false){

    if(askConfirm){

      Globals.modal.showConfirm("","Confermi di voler azzerare tutto?",()=>{

        this.list=[];
        this.total=0;
        this.total_paid=0;
        this.net_total=0;
        this.tax_total=0;
        this.discount_total=0;
        this.id_document_imported=0;
        this.documentImported=null

      },"Sì. Azzera","No","danger");
    }else{
      this.list=[];
        this.total=0;
        this.total_paid=0;
        this.net_total=0;
        this.tax_total=0;
        this.discount_total=0;
        this.id_document_imported=0;
        this.documentImported=null
    }

  }


  openDocuments(){
    Globals.modal.showModal(DocumentlistComponent,[
      {"name":"mode","value":"modal"},
      {"name":"state","value":1},{"name":"type","value":DocumentMode.sell}],(instance)=>{
      if(instance!=null){
        this.documentImported=instance['recordSelected'];
        
        //imposta tutti gli id a zero
        
        for(let d of this.documentImported['details'])
          d.id=0;
        
        this.list=this.documentImported['details'];
        this.id_document_imported=this.documentImported.id;



        this.calculateTotal();

      }
    });



  }
  

  createOrderSupplier(){
    //vai a confermare l'ordine cliente
    let list=this.list.filter(x=>(x['no_avaible']));

    this.documentsService.createOrderSupplier(list,this.id_inventory,()=>{
      Globals.message.showToaster("Ordine fornitore creato",ToastMode.SUCCESS);
      this.reset();
    });

  }


  createDDT(){

    if(this.list.length==0){
      Globals.message.showToaster("Inserire almeno un articolo nella lista",ToastMode.WARNING);
      return;
    }

    for(let i of this.list)
          i.state=2; //conferma 

    let toUnLoad=this.list;
    if(!this.module.getParam("force_unload"))
      toUnLoad=this.list.filter(x=>(!x['no_avaible'] || !x['product']['stockable']));
    if(toUnLoad.length==0){
      Globals.message.showToaster("Nessun prodotto disponibile",ToastMode.DANGER);
      return;
    }
    Globals.modal.showModal(DocumentsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":0},
      {"name":"details","value":toUnLoad},
      {"name":"autoUnload","value":false},
      {"name":"type_document","value":10},
      {"name":"id_document_linked","value":this.id_document_imported},
      {"name":"id_type","value":DocumentMode.sell},
      {"name":"origin","value":"SALES"},
      {"name":"description","value":"Trasferimento"},
      {"name":"private","value":1}],(instanceBilling)=>{
      if(instanceBilling!=null){
        this.reset();
        /*
        let document=instanceBilling["record"];
        
        for(let l of toUnLoad){
          l['id_table']=document['id'];
          l['table']="documents";
        }

        this.inventoriesDetailsService.saveMultiple(toUnLoad,()=>{
          this.reset();
        });*/
      }
    });
  }

  createNonFiscalDocument(){
    if(this.list.length==0){
      Globals.message.showToaster("Inserire almeno un voce di bene nella lista",ToastMode.WARNING);
      return;
    }

    for(let i of this.list)
          i.state=2; //conferma 

    let toUnLoad=this.list;
    if(!this.module.getParam("force_unload"))
      toUnLoad=this.list.filter(x=>(!x['no_avaible'] || !x['product']['stockable']));
    if(toUnLoad.length==0){
      Globals.message.showToaster("Nessun prodotto disponibile",ToastMode.DANGER);
      return;
    }
    Globals.modal.showModal(DocumentsDetailsComponent,[
      {"name":"mode","value":"modal"},
      {"name":"id","value":0},
      {"name":"details","value":toUnLoad},
      {"name":"autoUnload","value":false},
      {"name":"type_document","value":9},
      {"name":"id_document_linked","value":this.id_document_imported},
      {"name":"id_type","value":DocumentMode.sell},
      {"name":"description","value":""},
      {"name":"origin","value":"SALES"},
      
      {"name":"private","value":1}],(instanceBilling)=>{
      if(instanceBilling!=null){
        this.reset();
        /*let document=instanceBilling["record"];
        
        for(let l of toUnLoad){
          l['id_table']=document['id'];
          l['table']="documents";
        }

        this.inventoriesDetailsService.saveMultiple(toUnLoad,()=>{
          this.reset();
        });*/
      }
    });
  }


  createNonFiscalDocumentWithoutConfirm(){
    
    let details:DocumentDetail[]=[];
    Object.assign(details,this.list);
    for(let i=0;i<details.length;i++){
      details['id']=0;
    }
    
    
    //avvia il pagamento
    let doc=new Documents();
    doc['id_user']=Globals.user.id;
    doc['details']=details;
    doc['id_document_linked']=this.id_document_imported;
    doc['enable_inventory']=true;
    doc['methodpayment']="Contanti";
    doc['origin']="SALES";
    doc['id_address']=0;
    doc['id_table']=this.id_table;
    doc['table']=this.table;
    doc['amount']=this.total_paid;
    doc['created_from']=Globals.user['shop']?Globals.user['shop'].id:0;
    this.documentsService.createNonFiscal(doc,()=>{
      Globals.message.showToaster("Operazione completata",ToastMode.SUCCESS);
      this.reset();
    });
  }
  /*
  closePrintModal(){
    if(this.modalPrintRef)
      this.modalPrintRef.close("success");
  }

  closeConfirmReceiptModal(){
    if(this.modalConfirmReceiptRef)
      this.modalConfirmReceiptRef.close("success");
  }

  ConfirmReceiptModal(){
  
    this.modalConfirmReceiptRef.close("success");
    this.handleDocument();  
  }

  */
  openGenerateDocumentModal(){
    this.modalShow=true;
    this.modalGenerateDocumentRef=this.modalService.open(this.modalGenerateDocument);
  }
  closeGenerateDocumentModal(){
    if(this.modalGenerateDocumentRef){
      this.modalShow=false;
      this.modalGenerateDocumentRef.close("success");
    }
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event) {

    if(!this.modalShow && this.list.length>0){
      if(event.keyCode==113){ //F2
        this.receipt();
      }  
      if(event.keyCode==115){ //F4
        this.billing();
      }  
      if(event.keyCode==119){ //F8
        this.openGenerateDocumentModal();
      }
    }
    
  }


  addFromKeyboard(event){
    if(event.type==2){ //se è stato passato un EAN13 ricercalo nel catalogo
      this.goods.searchproductComponent.code=event.value;
      this.goods.searchproductComponent.searchProduct();

    }else{ //se è un prezzo

      let netprice=parseFloat(event.value)/(1+event.product.tax/100);

      let d=this.createRowInventories(event.product,event.quantity,netprice,event.percentual);
      this.list.push(d);
      
    }

    this.calculateTotal();

  }

  //conferma l'operazione
  confirm(){
    let default_confirm=this.module.getParam("default_confirm","receipt");
    if(default_confirm=="receipt"){
      this.receipt();
    }
    if(default_confirm=="nonfiscal"){
      this.createNonFiscalDocumentWithoutConfirm();
    }

  }

showModalMethodpayment(){
  Globals.modal.showModal(MethodpaymentComponent,[{"name":"total","value":"1"}],(instance)=>{
      return;
    },"sm");
}
  

}
